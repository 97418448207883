<template>
  <section class="m-index-cell m-padding">

      <router-link v-if="to" :to="to" class="m-cell-link">

        <div class="m-cell">
          <div class="m-cell-title">
            <slot name="icon"></slot>
            <span>{{title}}</span>
          </div>
          <div class="m-cell-value">
            <span>{{value}}</span>
            <img src="@/assets/index/icon_genduo@2x.png" alt="more">
          </div>
        </div>
        
      </router-link>

      <div v-else  class="m-cell-link">
        <div class="m-cell">
          <div class="m-cell-title">{{title}}</div>
        </div>
      </div>

  </section>
</template>

<script>
export default {
  name:'IndexGrid',
  props:['title', 'value', 'to', 'isLink', ]
}
</script>

<style lang="less" scoped>
.m-index-cell {
  .m-cell {
    display: flex;
    width: 100%;
    height: 25px;
    align-items: center;
    justify-content: space-between;
    .m-cell-title {
      font-size:18px;
      font-weight:600;
      color:#333333;
      line-height:25px;
      letter-spacing: 0.47px;
    }
    .m-cell-value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 25px;
      font-size:14px;
      font-weight:400;
      color:#666;
      line-height:20px;
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
}
</style>